import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Moment from "moment";
import { NewsItem } from "../services/news.service";
import { UpgradeEvent } from "../services/events.service";
import {
  Investment,
  ProjectItem,
  UserProjects,
} from "../services/project.service";
import { User } from "../services/user.service";
import Number from "../components/number/number";
import SimpleSlider from "../components/carousel-slider/carousel-slider";
import CardFloatingImgDetails from "../components/card-floating-img-details/card-floating-img-details";
import CardImage from "../components/card-with-image/card-with-image";
import styles from "../styles/pages/home.module.scss";
import generalStyles from "../styles/generals.module.scss";
import UppiesImage from "../img/uppies.svg";
import EarnImage from "../img/earn-arrow.svg";
import SmallArrowImage from "../img/small-arrow.svg";
import { Link } from "react-router-dom";
import Story from "../components/story/story";
import CallToActionCard from "../components/call-to-action-card/call-to-action-card";
import StarIcon from "../img/icons/194142/icon-star.svg";
import UppiesIcon from "../img/icons/194142/icon-uppies.svg";
import Owner from "../components/owner/Owner";
import Loading from "../components/loading/Loading";
import QuestionnaireService, {
  QuestionnaireContent,
} from "../services/questionnaire.service";
import PopupUE from "../components/popup/Popup";
import QuestionnaireBody from "../components/questionnaire-body/questionnaire-body";
import { Preferences } from "@capacitor/preferences";

export default function Index({
  user,
  cards,
  news,
  events,
  projects,
  investments,
  loading,
  modalShown,
  setModalShown,
}: {
  user: User | undefined;
  cards: UserProjects | undefined;
  news: NewsItem[];
  events: UpgradeEvent[];
  projects: ProjectItem[];
  investments: Investment[];
  loading: boolean;
  modalShown: boolean;
  setModalShown: (n: boolean) => void;
}) {
  const { t } = useTranslation("home");
  const { i18n } = useTranslation();

  const [showQuestionnaireModal, setShowQuestionnaireModal] =
    useState<boolean>(true);
  const [questionnaireContent, setQuestionnaireContent] = useState<
    QuestionnaireContent | undefined
  >(undefined);

  const checkImg = (event: UpgradeEvent) => {
    if (event.field_ac_event_img) {
      return process.env.REACT_APP_IMG_URL + "/" + event.field_ac_event_img;
    } else {
      return "/img/defaults/story.png";
    }
  };

  const checkImgNews = (news_item: NewsItem) => {
    if (news_item.content_type == "news") {
      return process.env.REACT_APP_IMG_URL + "/" + news_item.field_media_image;
    } else if (news_item.content_type == "press_item") {
      return process.env.REACT_APP_IMG_URL + "/" + news_item.field_header_image;
    } else {
      return "/img/defaults/story.png";
    }
  };

  const checkDate = (event: UpgradeEvent) => {
    Moment.locale("be");
    const date = event.field_event_date.split(" - ");
    const startDate = Moment(date[0]).format("DD.MM.YYYY");
    if (date.length == 2) {
      const endDate = Moment(date[1]).format("DD.MM.YYYY");
      if (startDate == endDate) {
        return startDate;
      } else {
        return startDate + " - " + endDate;
      }
    }

    return startDate;
  };

  useEffect(() => {
    if (!modalShown) {
      QuestionnaireService.getQuestionnaireData().then(
        async (questionnaireData) => {
          if (!questionnaireData) {
            return;
          }

          let questionnaire: QuestionnaireContent | undefined = undefined;

          if (i18n.language === "nl" && questionnaireData.nl) {
            questionnaire = questionnaireData.nl;
          }
          if (i18n.language === "fr" && questionnaireData.fr) {
            questionnaire = questionnaireData.fr;
          }
          if (i18n.language === "en" && questionnaireData.en) {
            questionnaire = questionnaireData.en;
          }
          // no questionnaire found
          if (!questionnaire) {
            if (questionnaireData.en) {
              questionnaire = questionnaireData.en;
            }
            if (questionnaireData.nl) {
              questionnaire = questionnaireData.nl;
            }
            if (questionnaireData.fr) {
              questionnaire = questionnaireData.fr;
            }
          }

          if (!questionnaire) {
            return;
          }

          const lastUrl: string | null = await Preferences.get({
            key: "lastQuestionnaireUrl",
          }).then((lastUrl) => lastUrl.value);
          const showQuestionnaire: boolean | null = await Preferences.get({
            key: "showQuestionnaire",
          }).then(
            (showQuestionnaire) =>
              showQuestionnaire.value && JSON.parse(showQuestionnaire.value)
          );

          if (lastUrl === null) {
            await Preferences.set({
              key: "lastQuestionnaireUrl",
              value: questionnaire.questionnaireUrl,
            });
          }
          if (showQuestionnaire === null) {
            await Preferences.set({
              key: "showQuestionnaire",
              value: JSON.stringify(true),
            });
          }

          if (lastUrl !== questionnaire.questionnaireUrl || showQuestionnaire) {
            setQuestionnaireContent(questionnaire);
            setShowQuestionnaireModal(true);
            await Preferences.set({
              key: "lastQuestionnaireUrl",
              value: questionnaire.questionnaireUrl,
            });
            await Preferences.set({
              key: "showQuestionnaire",
              value: JSON.stringify(true),
            });
          }
        }
      );
      setModalShown(true);
    }
  }, [modalShown, questionnaireContent]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {user ? (
            <div className={`${generalStyles.title}`}>
              {t("welcome")}{" "}
              {user.partner
                ? user.first_name + " - " + user.partner.first_name
                : user.first_name}
            </div>
          ) : (
            ""
          )}

          <div className={styles.numbers}>
            <Number
              numberBlockLink="/loyalty"
              icon={UppiesImage}
              number={user?.nr_uppies || 0}
              unit="Uppies"
              bgColor="purple"
            />
            <Number
              numberBlockLink="/investments"
              icon={EarnImage}
              number={cards?.total_amount || 0}
              unit={t("invested")}
              bgColor="green"
              prefix="€ "
            />
          </div>

          {user && user.owner && user.investor_role ? (
            <div className={styles.owner_block}>
              <Owner data={user.owner} />
            </div>
          ) : (
            ""
          )}

          <div className={styles.ctas}>
            <CallToActionCard
              text={t("vip_text")}
              href={t("vip_link")}
              icon={StarIcon}
              img_position="img_position_left"
            />
            <CallToActionCard
              text={t("earn_text")}
              href="/loyalty"
              icon={UppiesIcon}
              img_position="img_position_left"
            />
          </div>

          <div className={styles.news}>
            {news.length > 0 ? (
              <>
                <div className={styles.more}>
                  <div className={generalStyles.title}>{t("news")}</div>
                  <div className={styles.right}>
                    <div className={styles.text}>
                      <Link to="/news">{t("more_news_link_text")}</Link>
                    </div>
                    <div>
                      <img src={SmallArrowImage} />
                    </div>
                  </div>
                </div>
                <div className={styles.news_content}>
                  {news.slice(0, 2).map((news: NewsItem, i) => (
                    <Story
                      key={i}
                      title={news.title}
                      image={checkImgNews(news)}
                      date={`${news.field_ni_datum}`}
                      badge={news.field_type}
                      link={news.path}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>

          <div className={`${styles.news} ${styles.news_mobile}`}>
            {news.length > 0 ? (
              <>
                <div className={styles.more}>
                  <div className={generalStyles.title}>{t("news")}</div>
                  <div className={styles.right}>
                    <div className={styles.text}>
                      <Link to="/news">{t("more_news_link_text")}</Link>
                    </div>
                    <div>
                      <img src={SmallArrowImage} />
                    </div>
                  </div>
                </div>
                <div className={styles.slick_outer_wrapper}>
                  <SimpleSlider data={news.slice(0, 3)} />
                </div>
              </>
            ) : null}
          </div>

          <div>
            {investments.length > 0 ? (
              <>
                <div className={styles.more}>
                  <div className={generalStyles.title}>
                    {t("your_investments")}
                  </div>
                  <div className={styles.right}>
                    <div className={styles.text}>
                      <Link to="/investments">
                        {t("all_investments_link_text")}
                      </Link>
                    </div>
                    <div>
                      <img src={SmallArrowImage} />
                    </div>
                  </div>
                </div>
                <div className={styles.investments}>
                  {investments.slice(0, 2).map((investment, key) => (
                    <CardImage
                      key={key}
                      image={`${process.env.REACT_APP_IMG_URL}${investment.image}`}
                      title={investment.title}
                      amount={investment.amount}
                      img_inside={true}
                      link={"/investments/" + investment.id}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>

          <div>
            {events.length > 0 ? (
              <>
                <div className={styles.more}>
                  <div className={generalStyles.title}>{t("events")}</div>
                  <div className={styles.right}>
                    <div className={styles.text}>
                      <Link to="/events">{t("all_events_link_text")}</Link>
                    </div>
                    <div>
                      <img src={SmallArrowImage} />
                    </div>
                  </div>
                </div>
                <div className={styles.events}>
                  {events.slice(0, 2).map((event: UpgradeEvent, key) => (
                    <CardFloatingImgDetails
                      key={key}
                      content_title={event.title}
                      content_text={event.field_ac_event_teaser}
                      floating_text={[
                        checkDate(event),
                        event.field_ac_events_location,
                        Moment(event.field_event_date.split(" - ")[0]).format(
                          "HH:mm"
                        ),
                      ]}
                      image={checkImg(event)}
                      link={
                        event.field_ac_event_link
                          ? event.field_ac_event_link
                          : false
                      }
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <div className={styles.projects}>
            {projects.length > 0 ? (
              <>
                <div className={generalStyles.title}>
                  {t("current_investment_title")}
                </div>
                <div className={styles.slick_outer_wrapper}>
                  <SimpleSlider data={projects} />
                </div>
              </>
            ) : null}
          </div>
          {questionnaireContent !== undefined && (
            <PopupUE
              title={questionnaireContent.title}
              opened={showQuestionnaireModal}
              close={() => setShowQuestionnaireModal(false)}
              body={
                <QuestionnaireBody
                  questionnaireContent={questionnaireContent}
                  closePopup={() => setShowQuestionnaireModal(false)}
                />
              }
            />
          )}
        </div>
      )}
    </>
  );
}
