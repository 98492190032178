import Api from "./api/api";

export type LoyaltyLog = {
  name: string;
  text: string;
  creation_date: string;
  status: string;
  nr_uppies: number;
  source_type: string;
  giftcard: string;
};

class LoyaltyService {
  public async getLogs(): Promise<LoyaltyLog[]> {
    const result = await Api.getApi().get("/jsonapi/loyalty-logs");
    if (result.status < 200 || result.status > 299) {
      return [];
    } else return result.data;
  }
}

export default new LoyaltyService();
