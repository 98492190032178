import Header from "../header/header";
import Navbar from "../navbar/navbar";
import styles from "./layout.module.scss";
import React, { PropsWithChildren } from "react";
import Onboarding from "../onboarding/onboarding";
import Loading from "../loading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsBell from "../notifications/notificationsBell";
import { NotificationItem } from "../../services/notifications.service";

export default function Layout({
  children,
  onboardingSetting,
  notifications,
  setNotifications,
  isLoading,
}: PropsWithChildren<{
  onboardingSetting: "todo" | "done" | "not_set";
  notifications: NotificationItem[];
  setNotifications: (items: NotificationItem[]) => void;
  isLoading: boolean;
}>) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {onboardingSetting === "todo" ? (
        <div
          className={`${styles.outer_wrapper} ${styles.outer_wrapper_onboarding}`}
        >
          <Header
            fullWidth={false}
            onboarding={true}
            onBoardingSetting={onboardingSetting}
            notifications={notifications}
            setNotifications={setNotifications}
            isLoading={isLoading}
          />
          <Onboarding />
        </div>
      ) : onboardingSetting === "done" ? (
        <div className={styles.outer_wrapper}>
          <div className={styles.navbar_wrapper}>
            <div className={styles.navbar_inner_wrapper}>
              <Header
                onBoardingSetting={onboardingSetting}
                fullWidth={false}
                setNotifications={setNotifications}
                notifications={notifications}
                isLoading={isLoading}
              />
              <Navbar />
            </div>
          </div>
          <main>
            {location.pathname.startsWith("/profile/") ||
            location.pathname.includes("/sell") ||
            location.pathname.includes("/investments/") ||
            location.pathname.includes("/new-lead-") ||
            location.pathname.includes("/cash-in-uppies") ||
            location.pathname.includes("/submit-sell") ? (
              <button
                className={`${styles.return}`}
                onClick={() => navigate(-1)}
              >
                Return
              </button>
            ) : (
              ""
            )}
            {location.pathname !== "/notifications" ? (
              <div className={styles.notifications}>
                <NotificationsBell
                  setNotifications={setNotifications}
                  notifications={notifications}
                  isLoading={isLoading}
                />
              </div>
            ) : (
              ""
            )}
            {children}
          </main>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export function LayoutWide({
  children,
  notifications,
  setNotifications,
  onBoardingSetting,
  isLoading,
}: PropsWithChildren<{
  notifications: NotificationItem[];
  onBoardingSetting: "todo" | "done" | "not_set";
  setNotifications: (items: NotificationItem[]) => void;
  isLoading: boolean;
}>) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {onBoardingSetting === "todo" ? (
        <div className={styles.outer_wrapper}>
          <Header
            onBoardingSetting={onBoardingSetting}
            fullWidth={false}
            setNotifications={setNotifications}
            notifications={notifications}
            isLoading={isLoading}
          />
          <Onboarding />
        </div>
      ) : onBoardingSetting === "done" ? (
        <div className={styles.outer_wrapper}>
          {location.pathname.startsWith("/profile/") ? (
            <button className={`${styles.return}`} onClick={() => navigate(-1)}>
              Return
            </button>
          ) : (
            ""
          )}
          <div className={styles.navbar_wrapper}>
            <div className={styles.navbar_inner_wrapper}>
              <Header
                onBoardingSetting={onBoardingSetting}
                fullWidth={false}
                setNotifications={setNotifications}
                notifications={notifications}
                isLoading={isLoading}
              />
              <Navbar />
            </div>
          </div>
          <div className={styles.main_narrow}>
            {location.pathname !== "/notifications" ? (
              <div className={styles.notifications}>
                <NotificationsBell
                  notifications={notifications}
                  setNotifications={setNotifications}
                  isLoading={isLoading}
                />
              </div>
            ) : (
              ""
            )}

            {children}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}
