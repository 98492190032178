import React, { useState } from "react";
import Header from "../../components/header/header";
import PasswordReset from "../../img/password-reset-request.svg";
import styles from "../../styles/pages/auth.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import AuthService from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const navigation = useNavigate();
  const { t } = useTranslation("auth");
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  return (
    <div className={styles.auth}>
      <Header fullWidth={true} />
      <div className={styles.mainImage}>
        <img
          src={PasswordReset}
          alt={t("password-reset-image-alt")}
          width="540px"
          height="292px"
        />
      </div>
      <form
        onSubmit={async (e) => {
          setSubmitting(true);
          e.preventDefault();
          const result = await AuthService.requestPasswordReset(email);

          if (!result) {
            setError(true);
            setSubmitting(false);
          } else {
            await navigation("/auth/check-mailbox");
          }
        }}
      >
        <div className={styles.box}>
          <div className={generalStyles.authTitle}>{t("forgot-password")}</div>
          <div className={`${generalStyles.authText} ${styles.textMargin}`}>
            {t("forgot-password-text")}
          </div>
          <input
            className={generalStyles.input}
            type="email"
            placeholder={t("email")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required={true}
          />
          <button
            disabled={isSubmitting}
            type="submit"
            className={generalStyles.button}
          >
            {isSubmitting ? (
              <span className={generalStyles.spinner}></span>
            ) : (
              t("password-request-button")
            )}
          </button>
          {error ? (
            <div className={styles.warningText}>
              {t("password-request-error")}
            </div>
          ) : null}
          <div className={`${styles.accountText} ${styles.margin}`}>
            {t("terms")} <a href={t("privacy_link")}>{t("privacy")}</a>
          </div>
        </div>
      </form>
    </div>
  );
}
