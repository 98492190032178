import React, { useState } from "react";
import generalStyles from "../../styles/generals.module.scss";
import EyeOpen from "../../img/eye-open.svg";
import EyeClosed from "../../img/eye-closed.svg";
import styles from "./password-input.module.scss";

export default function PasswordInput({
  placeholder,
  value,
  setValue,
  required,
}: {
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  required: boolean;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={styles.password}>
      <input
        className={generalStyles.input}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        required={required}
      />
      <div
        onClick={() => {
          setShowPassword(!showPassword);
        }}
        className={styles.eyeToggle}
      >
        <img
          src={showPassword ? EyeClosed : EyeOpen}
          alt={"Show password"}
          height="16px"
          width="20px"
        />
      </div>
    </div>
  );
}
