import React from "react";
import styles from "./loading.module.scss";
import Logo from "../../img/loading_logo.svg";
import LoadingIcon from "../../img/loading_icon.svg";

export default function Loading() {
  return (
    <>
      <div className={styles.loading_wrapper}>
        <img className="" src={Logo} alt="Logo" width={380} height={90} />
        <img
          className=""
          src={LoadingIcon}
          alt="Loading icon"
          width={48}
          height={48}
        />
      </div>
    </>
  );
}
