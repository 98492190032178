import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import generalStyles from "../../styles/generals.module.scss";
import UserService from "../../services/user.service";
import { useTranslation } from "react-i18next";
import PopupUE from "../../components/popup/Popup";
import styles from "../../styles/forms/forms.module.scss";
import { useForm } from "react-hook-form";
import Loading from "../../components/loading/Loading";

export default function CashInForm({
  nrUppies,
  setNrUppies,
  loading,
}: {
  nrUppies: number;
  setNrUppies: (val: number) => void;
  loading: boolean;
}) {
  const [amountNr, setAmount] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [association, setAssociation] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const typeClean = type ? type.toLowerCase().replace(/ /g, "_") : "";

  const [popup, setPopup] = useState<boolean>();
  const [popupError, setPopupError] = useState<boolean>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const [isRadio, setIsRadio] = useState<number>(0);

  const { t } = useTranslation("cash_in");

  const onSubmit = (data: any) => {
    setSubmitting(true);
    const amountCashingIn = parseInt(amountNr) * 10;
    return new Promise<void>(() => {
      UserService.CashInUppies({
        email: data.email,
        amount: amountCashingIn,
        type: type,
      }).then((response) => {
        if (response) {
          reset();
          setEmail("");
          setAmount("");
          const newNumber = nrUppies - amountCashingIn;
          setNrUppies(newNumber);
          setSubmitting(false);
          openPopup();
        } else {
          setSubmitting(false);
          openErrorPopup();
        }
      });
    });
  };

  const openPopup = () => {
    setPopup(true);
  };

  const closePopup = () => {
    setPopup(false);
  };

  const openErrorPopup = () => {
    setPopupError(true);
  };

  const closeErrorPopup = () => {
    setPopupError(false);
  };

  const amounts = [250, 500, 1000, 2500, 3000, 5000, 7000, 10000];

  return (
    <>
      <div className={styles.cash_in_form}>
        <div className={`${generalStyles.title}`}>{t("title")}</div>
        <div className={styles.text}>{t(`choice_${typeClean}_text`)}</div>

        {!loading ? (
          nrUppies > 249 ? (
            <>
              <div className={styles.voucher_value_text}>
                <strong>{t("voucher_text")}</strong>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.cash_in_radios}>
                  {amounts.map((amount, key) => (
                    <>
                      {nrUppies && amount <= nrUppies ? (
                        <div key={key} className={styles.form_type_radio}>
                          <input
                            id={amount / 10 + "-eur"}
                            type="radio"
                            value={amount / 10}
                            {...register("amount", { required: true })}
                            onChange={(e) => {
                              setIsRadio(parseInt(e.target.value));
                              setAmount(e.target.value);
                            }}
                            checked={isRadio === amount / 10}
                          />
                          <label
                            className={`${errors.amount ? styles.error : ""}`}
                            htmlFor={amount / 10 + "-eur"}
                          >
                            <span>€ {amount / 10}</span>
                            <span>{amount} Uppies</span>
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </div>

                {errors.amount && (
                  <p className={styles.error_message}>
                    Amount {t("required")}.
                  </p>
                )}

                {type != "Solidarity" ? (
                  <>
                    <div className={styles.voucher_value_text}>
                      <strong>{t(`voucher_email_${typeClean}`)}</strong>
                    </div>
                    {type === "Non Profit" ? (
                      <>
                        <input
                          className={`${generalStyles.input} ${
                            errors.association ? styles.error : ""
                          }`}
                          placeholder={t("Naam van de vereninging")}
                          value={association}
                          {...register("association", { required: true })}
                          onChange={(e) => {
                            setAssociation(e.target.value);
                          }}
                        />
                        {errors.association && (
                          <p className={styles.error_message}>
                            Association {t("required")}.
                          </p>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <input
                      className={`${generalStyles.input} ${
                        errors.email ? styles.error : ""
                      }`}
                      placeholder={t("E-mailadres")}
                      value={email}
                      {...register("email", {
                        required: true,
                        pattern:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                      type={"email"}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    {errors.email && (
                      <p className={styles.error_message}>
                        Email {t("required")}.
                      </p>
                    )}
                  </>
                ) : (
                  ""
                )}

                <div>
                  <PopupUE
                    title={t("confirm_cash_in_title")}
                    body={t("confirm_cash_in_text")}
                    icon=""
                    opened={popup}
                    close={closePopup}
                  />
                </div>

                <div>
                  <PopupUE
                    title={t("confirm_cash_in_error")}
                    body={t("confirm_cash_in_text_error")}
                    icon=""
                    opened={popupError}
                    close={closeErrorPopup}
                  />
                </div>

                <button
                  disabled={isSubmitting}
                  className={`${generalStyles.button} ${generalStyles.green}`}
                >
                  {isSubmitting ? (
                    <span className={generalStyles.spinner} />
                  ) : (
                    t("submit")
                  )}
                </button>
              </form>
            </>
          ) : (
            <div className={styles.voucher_value_text}>
              <strong>
                {t("Sorry, you don't have enough Uppies to proceed.")}
              </strong>
            </div>
          )
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
