import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../../img/tooltip.svg";
import styles from "./gifts-card.module.scss";
import { useNavigate } from "react-router-dom";
import inAppBrowser from "../../services/mobile/browser";

export default function GiftsCard({
  label,
  text,
  href,
  image,
  tooltip,
  small,
}: {
  label: string;
  text: string;
  href: string;
  image: string;
  tooltip?: string;
  small?: boolean;
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation("loyalty");
  const navigate = useNavigate();

  const handleToggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div className={styles.grow}>
      <div className={styles.gift_block_wrapper}>
        <div className={styles.giftBlock}>
          <div className={styles.left_wrapper}>
            <img
              src={image}
              alt="Call to action icon"
              width={small ? 117 : 178}
              height={100}
            />
          </div>
          <div className={styles.right_wrapper}>
            <div
              className={styles.giftLabel}
              onClick={(event: any) => {
                inAppBrowser(href) ? navigate(href) : "";
              }}
            >
              {label}
            </div>
            <div className={styles.giftText}>{text}</div>
            <div
              className={styles.link}
              onClick={(event: any) => {
                inAppBrowser(href) ? navigate(href) : "";
              }}
            >
              {t("verzilver")}
            </div>
          </div>
        </div>
        {tooltip ? (
          <div className={styles.tooltip_wrapper} onClick={handleToggle}>
            <img
              src={Tooltip}
              width={24}
              height={24}
              className={styles.tooltip}
            ></img>

            <div
              className={
                styles.tooltip_text +
                `${tooltipOpen ? " " + styles.active : ""}`
              }
            >
              {tooltip}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
