import { DateTime } from "luxon";
import Api from "./api/api";

export type UpgradeEvent = {
  title: string;
  field_event_date: string;
  field_ac_event_teaser: string;
  field_ac_events_location: string;
  field_ac_event_link: any;
  field_ac_event_img: any;
  path: string;
};

export type UpgradeEvents = {
  data: UpgradeEvent[];
};

class EventsService {
  getEvents(language: string): Promise<UpgradeEvents> {
    const date = new Date();
    return Api.getApi().get("/" + language + "/jsonapi/events-app");
  }
}

export default new EventsService();
