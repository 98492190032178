import React from "react";
import styles from "./card-with-icon.module.scss";
import ArrowImage from "../../img/arrow-right.svg";
import { Link } from "react-router-dom";

export default function CardWithIcon({
  title,
  icon,
  conditions,
  link,
  link_text,
  margin,
  type,
}: {
  title: string;
  icon: any;
  conditions: { icon: any; text: string }[];
  link: string;
  link_text: string;
  margin?: boolean;
  type?: string;
}) {
  return (
    <div className={`${styles.cardIconBox} ${margin ? styles.margin : ""}`}>
      <div className={`${styles.image}`}>
        <img className={styles.project_img} src={icon} width={64} height={64} />
      </div>
      <div className={styles.content_wrapper}>
        <div className={styles.title}>{title}</div>
        {conditions.map((condition, i) => {
          return (
            <div className={styles.condition} key={i}>
              <div className={styles.icon}>
                <img src={condition.icon} height={20} width={20} alt={"icon"} />
              </div>
              <div className={styles.text}>{condition.text}</div>
            </div>
          );
        })}
        <Link to={link}>
          <div className={styles.link}>
            <div>{link_text}</div>
            <img src={ArrowImage} height={20} width={20} alt={"Arrow"} />
          </div>
        </Link>
      </div>
    </div>
  );
}
