import React from "react";
import { User, LanguageSetting } from "../../services/user.service";
import generalStyles from "../../styles/generals.module.scss";
import ProfileImage from "../../img/profile/account.svg";
import BellImage from "../../img/bell_profile.svg";
import Language from "../../img/profile/language.svg";
import Privacy from "../../img/profile/privacy.svg";
import Helpdesk from "../../img/profile/helpdesk.svg";
import SmallArrowImage from "../../img/small-arrow.svg";
import styles from "../../styles/pages/profile.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Owner from "../../components/owner/Owner";

export default function Profile({
  user,
  languageSetting,
}: {
  user: User | undefined;
  languageSetting: LanguageSetting;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("profile");

  const profileItems = [
    {
      name: t("edit_profile"),
      icon: ProfileImage,
      link: "#/profile/edit",
      target: "_self",
    },
    {
      name: t("notifications"),
      icon: BellImage,
      link: "#/profile/notifications",
      target: "_self",
    },
    {
      name: t("languages"),
      icon: Language,
      link: "#/profile/languages",
      target: "_self",
    },
    {
      name: "Privacy Policy",
      icon: Privacy,
      link: "https://upgrade-estate.be/nl/privacybeleid",
      target: "_blank",
    },
    {
      name: t("helpcenter"),
      icon: Helpdesk,
      link: "#/profile/helpcenter",
      target: "_self",
    },
  ];

  return (
    <>
      <div className={`${generalStyles.title} ${generalStyles.align_center}`}>
        {t("profile")}
      </div>
      {user !== undefined ? (
        <div className={styles.profile}>
          <div className={styles.image}>
            {user ? (
              user.image ? (
                <img
                  className={styles.user_profile_image}
                  src={process.env.REACT_APP_IMG_URL + user.image}
                  height={109}
                  width={109}
                  alt=""
                />
              ) : (
                <span className={styles.user_profile_image}>
                  {Array.from(user.first_name)[0]}
                </span>
              )
            ) : (
              ""
            )}
          </div>
          <div className={styles.name}>
            {user.partner ? (
              <>
                <div>{user.first_name + " - " + user.partner.first_name}</div>
                <div>{user.last_name + " - " + user.partner.last_name}</div>
              </>
            ) : (
              user.first_name + " " + user.last_name
            )}
          </div>
          <div className={styles.email}>
            <Link to={`mailto:${user.email}`}>{user.email}</Link>
          </div>

          {user && user.owner && user.investor_role ? (
            <div>
              <Owner data={user.owner} no_mail={true} hide_right_box={true} />
            </div>
          ) : (
            ""
          )}

          <div className={`${styles.items} ${styles.menu}`}>
            {profileItems.map((item, i) => {
              return (
                <a href={item.link} key={i} target={item.target}>
                  <div className={styles.item}>
                    <div className={styles.itemIcon}>
                      <img src={item.icon} height={24} width={24} alt="" />
                    </div>
                    <div className={styles.itemText}>{item.name}</div>
                    <div className={styles.itemArrow}>
                      <span className={styles.language_setting}>
                        {item.link === "#/profile/languages"
                          ? "(" + languageSetting + ")"
                          : ""}
                      </span>
                      <img
                        src={SmallArrowImage}
                        height={20}
                        width={10}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
          <span className={styles.appVersion}>
            Version {process.env.REACT_APP_VERSION}
          </span>
          <div
            className={`${generalStyles.button} ${generalStyles.red}`}
            onClick={async () => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            {t("logout")}
          </div>
        </div>
      ) : null}
    </>
  );
}
