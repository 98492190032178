import CardFloatingImg from "../components/card-floating-img/card-floating-img";
import styles from "../styles/pages/benefits.module.scss";
import generalStyles from "../styles/generals.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import EntryIcon from "../img/benefits/building.svg";
import VipIcon from "../img/benefits/star.svg";
import LoyaltyIcon from "../img/benefits/loyalty.svg";
import AcademyIcon from "../img/logos/194142/academy.svg";

export default function Benefits() {
  const { t } = useTranslation("benefits");

  return (
    <>
      <div className={`${generalStyles.title}`}>{t("title")}</div>
      <div className={styles.card_floating_img_cards}>
        <CardFloatingImg
          text_floating={t("entrycost_title")}
          text_content={t("entrycost_description")}
          link={t("entrycost_cta")}
          link_href={t("entrycost_link")}
          target={"_blank"}
          icon={EntryIcon}
        />
        <CardFloatingImg
          text_floating={t("vip_title")}
          text_content={t("vip_description")}
          link={t("vip_cta")}
          link_href={t("vip_link")}
          target={"_blank"}
          icon={VipIcon}
        />
        <CardFloatingImg
          text_floating={t("academy_title")}
          text_content={t("academy_description")}
          link={t("academy_cta")}
          link_href="/#/events"
          icon={AcademyIcon}
        />
        <CardFloatingImg
          text_floating={t("loyalty_title")}
          text_content={t("loyalty_description")}
          link={t("loyalty_cta")}
          link_href="/#/loyalty"
          icon={LoyaltyIcon}
        />
      </div>
    </>
  );
}
