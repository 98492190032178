import styles from "./notifications.module.scss";
import BellImage from "../../img/bell.svg";
import NotificationImage from "../../img/bell-notification.svg";
import React, { useEffect, useState } from "react";
import NotificationsService, {
  NotificationItem,
} from "../../services/notifications.service";
import { Link } from "react-router-dom";

export default function NotificationsBell({
  notifications,
  setNotifications,
  isLoading,
}: {
  notifications: NotificationItem[];
  setNotifications: (items: NotificationItem[]) => void;
  isLoading: boolean;
}) {
  return (
    <>
      {isLoading ? (
        <Link to={"/notifications"}>
          <img
            src={NotificationImage}
            alt="notification image"
            width={25}
            height={25}
          />
        </Link>
      ) : (
        <Link to={"/notifications"}>
          <img
            src={
              notifications &&
              notifications.filter((n) => n.status === "unread").length > 0
                ? NotificationImage
                : BellImage
            }
            alt="notification image"
            width={25}
            height={25}
          />
        </Link>
      )}
    </>
  );
}
