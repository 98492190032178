import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userService from "../../services/user.service";

export default function AuthenticationGuard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [time, setTime] = useState(0);

  const unprotectedPaths = [
    "/auth/login",
    "/auth/activate-account",
    "/auth/password-reset-request",
    "/auth/check-mailbox",
    "/auth/check-account",
  ];

  useEffect(() => {
    if (unprotectedPaths.includes(location.pathname)) {
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/auth/login");
      return;
    }

    const tokenParts = token.split(/\./);
    try {
      decodeBase64JWTToken(tokenParts[1]);
    } catch (e) {
      localStorage.clear();
      window.location.reload();
      return;
    }

    const decodedToken: { exp: number } = JSON.parse(
      decodeBase64JWTToken(tokenParts[1])
    );

    userService.getDrupalTime().then((response) => {
      setTime(response.data.time);
      if (
        DateTime.fromMillis(decodedToken.exp * 1000).toUTC() <
        DateTime.fromMillis(time * 1000).toUTC()
      ) {
        //TODO fix this refresh function
        //the error is to do with the site continuing to load all components and thus requesting more refreshes, which then fail.
        //const refresh = authService.refreshToken().then((response) => {
        //if (!response) {
        localStorage.clear();
        window.location.reload();
        return;
        //}
        //});
      }
    });
  }, [unprotectedPaths, navigate, location]);

  return null;
}

function decodeBase64JWTToken(token: string) {
  const base64 = token.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return jsonPayload;
}
