import React from "react";
import { useTranslation } from "react-i18next";
import CardFloatingImgDetails from "../components/card-floating-img-details/card-floating-img-details";
import styles from "../styles/pages/events.module.scss";
import { UpgradeEvent } from "../services/events.service";
import Moment from "moment";
import generalStyles from "../styles/generals.module.scss";
import Loading from "../components/loading/Loading";

export default function Events({
  events,
  loading,
}: {
  events: UpgradeEvent[];
  loading: boolean;
}) {
  const { t } = useTranslation("events");

  const checkImg = (event: UpgradeEvent) => {
    if (event.field_ac_event_img) {
      return process.env.REACT_APP_IMG_URL + "/" + event.field_ac_event_img;
    } else {
      return "/img/defaults/story.png";
    }
  };

  const checkDate = (event: UpgradeEvent) => {
    Moment.locale("be");
    const date = event.field_event_date.split(" - ");
    const startDate = Moment(date[0]).format("DD.MM.YYYY");
    if (date.length == 2) {
      const endDate = Moment(date[1]).format("DD.MM.YYYY");
      if (startDate == endDate) {
        return startDate;
      } else {
        return startDate + " - " + endDate;
      }
    }

    return startDate;
  };

  return (
    <>
      <div className={`${generalStyles.title}`}>{t("events")}</div>
      {events && events.length > 0 ? (
        <div className={styles.card_floating_img_cards}>
          {events.map((event, key) => (
            <CardFloatingImgDetails
              key={key}
              content_title={event.title}
              content_text={event.field_ac_event_teaser}
              floating_text={[
                checkDate(event),
                event.field_ac_events_location,
                Moment(event.field_event_date.split(" - ")[0]).format("HH:mm"),
              ]}
              image={checkImg(event)}
              link={
                event.field_ac_event_link ? event.field_ac_event_link : false
              }
            />
          ))}
        </div>
      ) : (
        <h3>{loading ? <Loading /> : t("no_events")}</h3>
      )}
    </>
  );
}
