import axios from "axios";
import { Preferences } from "@capacitor/preferences";

class Api {
  public getApi() {
    const endpointsWithoutAuthentication = [
      "/jsonapi/events",
      "/jsonapi/news",
      "/jsonapi/projects",
      "/jsonapi/get-drupal-time",
    ];

    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem("token");
      const type = localStorage.getItem("type");

      if (!config.url) {
        return config;
      }

      if (
        token &&
        type &&
        !endpointsWithoutAuthentication.includes(config.url) &&
        config.headers
      ) {
        config.headers["Authorization"] = `${type} ${token}`;
      }

      return config;
    });

    instance.interceptors.response.use();

    return instance;
  }

  public getRefreshTokenApi() {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    instance.interceptors.request.use((config) => {
      if (!config.url) {
        return config;
      }

      return config;
    });

    //empty interceptor: no auth required.
    instance.interceptors.response.use();

    return instance;
  }
}

export default new Api();
