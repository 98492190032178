import React from "react";
import { useNavigate } from "react-router-dom";
import inAppBrowser from "../../services/mobile/browser";
import styles from "./card-with-image.module.scss";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

export default function CardImage({
  image,
  title,
  amount,
  link,
  img_inside,
}: {
  image: string;
  title: string;
  amount: number;
  link: string;
  img_inside?: boolean;
}) {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`${img_inside ? styles.img_inside : ""} ${
          styles.project_card
        }`}
        onClick={(event: any) => {
          inAppBrowser(link) ? navigate(link) : "";
        }}
      >
        <div className={styles.project_img}>
          <img src={image} width={170} height={170} alt={title} />
        </div>
        <div className={styles.content_wrapper}>
          <div className={styles.title}>{title}</div>
          <div className={styles.investing}>{t("invested")}</div>
          <div className={styles.price}>
            <NumericFormat
              displayType="text"
              value={amount}
              allowLeadingZeros
              thousandSeparator="."
              decimalSeparator=","
              prefix="€ "
            />
          </div>
          <div className={styles.link}></div>
        </div>
      </div>
    </>
  );
}
