import React from "react";
import styles from "./card-floating-img-details.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import inAppBrowser from "../../services/mobile/browser";
import { decode } from "html-entities";

export default function CardFloatingImgDetails(props: any) {
  const { t } = useTranslation("events");
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.card_floating_img_wrapper}>
        <div className={styles.floating_box}>
          <div className={styles.floating_image}>
            <img
              src={props.image}
              alt="number block image"
              width={222}
              height={222}
            />
          </div>
          <div className={styles.floating_text}>
            {props.floating_text.map((element: string, index: number) => {
              return <span key={index}>{element}</span>;
            })}
          </div>
        </div>
        <div className={styles.content_box}>
          <div className={styles.content_box_text}>
            <div className={styles.content_title}>
              {decode(props.content_title)}
            </div>
            {decode(props.content_text.substring(0, 250) + "...")}
          </div>

          {props.link ? (
            <span
              onClick={(event: any) => {
                inAppBrowser(props.link) ? navigate(props.link) : "";
              }}
            >
              <a
                className={styles.link}
                target={props.target ? props.target : "_self"}
              >
                {t("more_info")}
              </a>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
