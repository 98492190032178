import styles from "./navbar.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import UserService, { User } from "../../services/user.service";
import CloseIcon from "../../img/icons/FFFFFF/icon-close.svg";

export default function Navbar() {
  const location = useLocation();
  const { t } = useTranslation("navbar");

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [moreMenu, setMoreMenu] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
    setMoreMenu(!moreMenu);
  };

  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    try {
      UserService.getUser().then((response) => {
        setUser(response.data);
      });
    } catch {
      // do nothing
    }
  }, []);

  return (
    <nav className={styles.nav}>
      <NavLink
        to="/"
        onClick={() => setNavbarOpen(false)}
        className={() => {
          let style = `${styles.home}`;
          if (location.pathname === "/" && !moreMenu) {
            style += ` ${styles.active}`;
          }

          return style;
        }}
      >
        <>
          {t("home")}
          <span>{t("home")}</span>
        </>
      </NavLink>
      <NavLink
        to="/loyalty"
        onClick={() => setNavbarOpen(false)}
        className={() => {
          let style = `${styles.loyalty}`;
          if (
            [
              "/loyalty",
              "/new-lead-employee",
              "/new-lead-investor",
              "/new-lead-property",
              "/cash-in-uppies",
            ].includes(location.pathname) &&
            !moreMenu
          ) {
            style += ` ${styles.active}`;
          }

          return style;
        }}
      >
        <>
          {t("loyalty")}
          <span>{t("loyalty")}</span>
        </>
      </NavLink>
      <NavLink
        to="/investments"
        onClick={() => setNavbarOpen(false)}
        className={() => {
          let style = `${styles.investments}`;
          if (location.pathname.startsWith("/investments") && !moreMenu) {
            style += ` ${styles.active}`;
          }

          return style;
        }}
      >
        <>
          {t("investments")}
          <span>{t("investments")}</span>
        </>
      </NavLink>
      <NavLink
        to="/benefits"
        onClick={() => setNavbarOpen(false)}
        className={() => {
          let style = `${styles.benefits}`;
          if (location.pathname === "/benefits" && !moreMenu) {
            style += ` ${styles.active}`;
          }

          return style;
        }}
      >
        <>
          {t("benefits")}
          <span>{t("benefits")}</span>
        </>
      </NavLink>

      <a
        onClick={handleToggle}
        className={styles.more + `${navbarOpen ? " " + styles.active : ""}`}
      >
        {t("more")}
      </a>

      {/* More list for mobile*/}
      <div
        className={styles.moreList + `${navbarOpen ? " " + styles.open : ""}`}
      >
        <a className={styles.close} onClick={handleToggle}>
          <img src={CloseIcon} alt="Close popup" width={24} height={24} />
        </a>
        <h3>{t("menu")}</h3>
        <NavLink
          to="/news"
          onClick={handleToggle}
          className={() => {
            let style = `${styles.news}`;
            if (location.pathname === "/news") {
              style += ` ${styles.active}`;
            }

            return style;
          }}
        >
          <>
            {t("news")}
            <span>{t("news")}</span>
          </>
        </NavLink>
        <NavLink
          to="/events"
          onClick={handleToggle}
          className={() => {
            let style = `${styles.events}`;
            if (location.pathname === "/events") {
              style += ` ${styles.active}`;
            }

            return style;
          }}
        >
          <>
            {t("events")}
            <span>{t("events")}</span>
          </>
        </NavLink>
        <NavLink
          to="/documents"
          onClick={handleToggle}
          className={() => {
            let style = `${styles.documents}`;
            if (location.pathname === "/documents") {
              style += ` ${styles.active}`;
            }

            return style;
          }}
        >
          <>
            {t("documents")}
            <span>{t("documents")}</span>
          </>
        </NavLink>

        <div className={styles.divider} />
        <NavLink
          to="/profile/helpcenter"
          onClick={handleToggle}
          className={() => {
            let style = `${styles.help}`;
            if (location.pathname.startsWith("/profile/helpcenter")) {
              style += ` ${styles.active}`;
            }

            return style;
          }}
        >
          <>
            {t("helpcenter")}
            <span>{t("helpcenter")}</span>
          </>
        </NavLink>
        <div className={styles.links_wrapper}>
          <NavLink
            to="/profile"
            onClick={handleToggle}
            className={() => {
              let style = `${styles.profile}`;
              if (
                location.pathname.startsWith("/profile") &&
                !location.pathname.startsWith("/profile/helpcenter")
              ) {
                style += ` ${styles.active}`;
              }

              return style;
            }}
          >
            <>
              {user ? (
                user.image ? (
                  <img
                    className={styles.user_profile_image}
                    src={process.env.REACT_APP_IMG_URL + user.image}
                    height={32}
                    width={32}
                    alt=""
                  />
                ) : (
                  <span className={styles.user_profile_image}>
                    {Array.from(user.first_name)[0]}
                  </span>
                )
              ) : (
                ""
              )}

              {t("my profile")}
            </>
          </NavLink>
        </div>
      </div>
    </nav>
  );
}
