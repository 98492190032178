import React, { useState } from "react";
import UserService, { LanguageSetting } from "../../services/user.service";
import generalStyles from "../../styles/generals.module.scss";
import styles from "../../styles/pages/profile.module.scss";
import SelectedIcon from "../../img/profile/language/selected.svg";
import { useTranslation } from "react-i18next";

export default function Languages() {
  const { i18n, t } = useTranslation("profile");
  const [languageSetting, setLanguageSetting] = useState<LanguageSetting>(
    i18n.language as LanguageSetting
  );

  const supportedLanguages = ["nl", "fr"];

  return (
    <>
      <div
        className={`${generalStyles.title} ${generalStyles.align_center} ${generalStyles.topMargin}`}
      >
        {t("languages")}
      </div>
      <div className={styles.profile}>
        <div className={styles.settings}>
          {supportedLanguages.map((language, i) => {
            return (
              <div
                className={`${styles.item} ${styles.itemClickable} ${styles.itemWithBottomLine}`}
                key={i}
                onClick={async () => {
                  setLanguageSetting(language as LanguageSetting);
                  await UserService.updateUserLanguageSettings(
                    language as LanguageSetting
                  );

                  window.location.reload();
                }}
              >
                <div>
                  <div className={styles.itemText}>{t(language)}</div>
                </div>
                <div className={styles.itemCheckbox}>
                  {language === languageSetting ? (
                    <img src={SelectedIcon} alt={"Selected icon"} />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
