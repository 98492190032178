import React from "react";
import { LoyaltyLog } from "../../services/loyalty.service";
import styles from "./loyalty-logs.module.scss";
import DefaultImage from "../../img/loyalty_log/loyalty_uppies.svg";

import CashedInImage from "../../img/loyalty_log/loyalty_cashed_in.svg";
import DonateImage from "../../img/loyalty_log/loyalty_donate.svg";
import RecommendedImage from "../../img/loyalty_log/loyalty_recommended.svg";
import InvestorImage from "../../img/loyalty_log/loyalty_investor.svg";
import EmployeeImage from "../../img/loyalty_log/loyalty_employee.svg";
import PropertyImage from "../../img/loyalty_log/loyalty_property.svg";
import InvestmentImage from "../../img/loyalty_log/loyalty_investment.svg";
import WaitingImage from "../../img/loyalty_log/loyalty_waiting.svg";
import DeclinedImage from "../../img/loyalty_log/loyalty_declined.svg";

export function LoyaltyLogs({
  logs,
  noLogsMessage,
}: {
  logs: LoyaltyLog[];
  noLogsMessage: string;
}) {
  function getLoyaltyLogImage(
    source: string,
    giftcard: string,
    status: string
  ) {
    if (status == "waiting") {
      return WaitingImage;
    } else if (status == "declined") {
      return DeclinedImage;
    } else {
      switch (source) {
        case "cashed_in":
          return giftcard === "donate" || giftcard === "solidarity"
            ? DonateImage
            : CashedInImage;
        case "recommended":
          return RecommendedImage;
        case "investment":
          return InvestmentImage;
        case "recommendation_investor":
          return InvestorImage;
        case "recommendation_employee":
          return EmployeeImage;
        case "recommendation_property ":
          return PropertyImage;
        case "new_investment":
          return InvestmentImage;
        default:
          return DefaultImage;
      }
    }
  }
  return (
    <>
      {
        <div className={styles.loyalty}>
          {logs && logs.length ? (
            <>
              {logs.map((log, index) => (
                <div className={styles.loyalty_log} key={index}>
                  <div className={styles.image}>
                    <img
                      src={getLoyaltyLogImage(
                        log.source_type,
                        log.giftcard,
                        log.status
                      )}
                      height={56}
                      width={56}
                      alt={"icon"}
                    />
                  </div>
                  <div className={styles.detailsBox}>
                    <div className={styles.log_name}>{log.text}</div>
                    {log.status == "declined" ? (
                      <div className={styles.log_date}>Afgekeurd</div>
                    ) : log.status == "waiting" ? (
                      <div className={styles.log_date}>
                        In afwachting van goedkeuring
                      </div>
                    ) : (
                      <div className={styles.log_date}>{log.creation_date}</div>
                    )}
                  </div>
                  {log.status == "declined" ? (
                    ""
                  ) : (
                    <div
                      className={`${styles.number_uppies} ${
                        styles[log.status]
                      }`}
                    >
                      <>
                        {log.status == "plus" || log.status == "waiting"
                          ? "+ "
                          : "- "}
                        {log.nr_uppies}
                      </>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <h3>{noLogsMessage}</h3>
          )}
        </div>
      }
    </>
  );
}
