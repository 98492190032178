import React from "react";
import styles from "./story.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import inAppBrowser from "../../services/mobile/browser";
import { stripHtml } from "string-strip-html";
import { decode } from "html-entities";
import HtmlParser from "html-react-parser";

export default function Story({
  title,
  date,
  badge,
  image,
  link,
  link_text,
  body,
  slider,
}: {
  title: string;
  date?: string;
  badge: string;
  image: string;
  link: string;
  link_text?: string;
  body?: string;
  slider?: boolean;
}) {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  const convertToClass = (convertable: string) => {
    return convertable.replace(/\s+/g, "-").toLowerCase();
  };

  return (
    <>
      <div className={styles.story + `${slider ? " " + styles.slider : ""}`}>
        <div className={styles.img_wrapper}>
          {badge && (
            <span
              className={`${styles.badge} ${
                styles["badge_" + convertToClass(badge)]
              }`}
            >
              {badge}
            </span>
          )}
          <img src={image} alt={title} height={240} />
        </div>
        <div className={styles.content_wrapper}>
          {date && <div className={styles.date}>{`${date}`}</div>}
          {title && (
            <h3
              className={styles.title}
              onClick={(event: any) => {
                inAppBrowser(link) ? navigate(link) : "";
              }}
            >
              {decode(title)}
            </h3>
          )}

          <div className={styles.body}>
            {body ? HtmlParser(decode(body.substring(0, 400))) : ""}
          </div>
          <span
            onClick={(event: any) => {
              inAppBrowser(link) ? navigate(link) : "";
            }}
            className={styles.link}
          >
            {link_text ? link_text : t("read_on")}
          </span>
        </div>
      </div>
    </>
  );
}
