import React from "react";
import Header from "../../components/header/header";
import MailIcon from "../../img/mail-icon-green.svg";
import styles from "../../styles/pages/auth.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function MailboxAccount() {
  const { t } = useTranslation("auth");

  return (
    <div className={`${styles.auth} ${generalStyles.alignTextCenter}`}>
      <Header fullWidth={true} />
      <div className={styles.middle_wrapper}>
        <div className={styles.mailboxImage}>
          <img
            src={MailIcon}
            alt={t("email-imgage-alt")}
            width={120}
            height={120}
          />
        </div>
        <div className={styles.box}>
          <div className={`${generalStyles.authTitle} ${generalStyles.small}`}>
            {t("check-account")}
          </div>
          <div className={`${generalStyles.authText} ${styles.textMargin}`}>
            {t("email-account")}
          </div>
          {/* <Link
            to="/auth/login"
            className={`${generalStyles.button} ${styles.button_login}`}
          >
            {t("login")}
          </Link> */}
        </div>
      </div>

      <div className={styles.box_bottom}>
        <div className={`${styles.accountText} ${styles.margin}`}>
          {t("email-not-received")}{" "}
          <a href={`mailto:${process.env.REACT_APP_HELP_EMAIL}`}>
            {t("contact-us")}
          </a>
          .
        </div>
      </div>
    </div>
  );
}
