import styles from "./benefits.module.scss";
import React from "react";
import { Link } from "react-router-dom";

export default function BenefitsBlocks({
  position,
  title,
  text,
  img,
  link,
  linkText,
}: {
  title?: string;
  text?: string;
  img: string;
  position?: "img_position_right" | "img_position_left";
  link?: string;
  linkText?: string;
}) {
  return (
    <div
      className={`${styles.benefit_block} ${position ? styles[position] : ""}`}
    >
      <img src={img} alt="project image" width={48} height={48} />
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        {text}
        {link ? (
          <div>
            <Link className={styles.link} to={link}>
              {linkText}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
