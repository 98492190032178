import { QuestionnaireContent } from "../../services/questionnaire.service";
import React from "react";
import generalStyles from "../../styles/generals.module.scss";
import styles from "./questionnaire-body.module.scss";
import { Preferences } from "@capacitor/preferences";
import { useTranslation } from "react-i18next";
import { Browser } from "@capacitor/browser";
import parse from "html-react-parser";

export default function QuestionnaireBody({
  questionnaireContent,
  closePopup,
}: {
  questionnaireContent: QuestionnaireContent;
  closePopup: () => void;
}) {
  const { t } = useTranslation("questionnaire");

  function openQuestionnaire() {
    Browser.open({ url: questionnaireContent.questionnaireUrl }).then(() =>
      closePopup()
    );
  }

  function disablePopup() {
    Preferences.set({
      key: "showQuestionnaire",
      value: JSON.stringify(false),
    }).then(() => closePopup());
  }

  return (
    <>
      <div>{parse(questionnaireContent.description)}</div>
      <button
        className={`${generalStyles.button} ${generalStyles.green}`}
        onClick={() => openQuestionnaire()}
      >
        {questionnaireContent.buttonText}
      </button>
      <button
        className={`${styles.hide_button}`}
        onClick={() => disablePopup()}
      >
        {t("hideQuestionnaire")}
      </button>
    </>
  );
}
