import { Browser } from "@capacitor/browser";

export default function inAppBrowser(uri) {
  if (uri.substr(0, 4) === "http") {
    //TODO change this because it breaks application form url parameters
    //Browser.open({ url: uri + "?source=MobileApp" });
    Browser.open({ url: uri });
    return false;
  }
  if (uri.substr(0, 3) === "tel") {
    window.open({ url: uri });
    return false;
  }
  if (uri.substr(0, 5) === "mailto") {
    window.open({ url: uri });
    return false;
  }
  return true;
}
