import React from "react";
import styles from "./call-to-action-card.module.scss";
import ArrowImage from "../../img/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import inAppBrowser from "../../services/mobile/browser";

export default function CallToActionCard({
  text,
  href,
  icon,
  img_position,
  target,
}: {
  text: string;
  href: string;
  icon: any;
  img_position?: "img_position_left";
  target?: "_self";
}) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${styles.formBlock} ${
          img_position ? styles[img_position] : ""
        }`}
        onClick={(event: any) => {
          inAppBrowser(href) ? navigate(href) : "";
        }}
      >
        <div className={styles.blockImage}>
          <img
            src={icon}
            alt="Call to action block icon"
            width={40}
            height={40}
          />
        </div>
        <div className={styles.content_wrapper}>
          <div className={styles.callToAction}>
            <div>{text}</div>
            <div className={styles.link}></div>
          </div>
        </div>
      </div>
    </>
  );
}
