import { DateTime } from "luxon";
import Api from "./api/api";

export type NewsItem = {
  title: string;
  field_ni_datum: DateTime;
  field_type: string;
  field_media_image: string;
  field_header_image: string;
  path: string;
  content_type: string;
};

export type NewsItems = {
  data: NewsItem[];
};

class NewsService {
  async getNews(language: string): Promise<NewsItems> {
    return Api.getApi().get("/" + language + "/jsonapi/news-app");
  }
}

export default new NewsService();
