import generalStyles from "../../styles/generals.module.scss";
import CallIcon from "../../img/profile/helpcenter/phone.svg";
import CalendarIcon from "../../img/profile/helpcenter/calendar.svg";
import MailIcon from "../../img/profile/helpcenter/mail.svg";
import SmallArrowImage from "../../img/small-arrow.svg";
import styles from "../../styles/pages/profile.module.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import inAppBrowser from "../../services/mobile/browser";
import { Capacitor } from "@capacitor/core";

export default function Helpcenter() {
  const [openQaItem, setOpenQaItem] = useState<string>("");
  const { t } = useTranslation("profile");
  const navigate = useNavigate();

  const qaItems = [
    {
      id: "uppiesValidityLengthId",
      title: "uppiesValidityLengthTitle",
      text: "uppiesValidityLengthText",
      disclaimer: "uppiesValidityLengthDisclaimer",
    },
    {
      id: "uppiesCashoutId",
      title: "uppiesCashoutTitle",
      text: "uppiesCashoutText",
    },
    {
      id: "investmentValueId",
      title: "investmentValueTitle",
      text: "investmentValueText",
    },
  ];

  const contactItems = [
    {
      id: "call",
      title: "call",
      href: "tel:003292337666",
      icon: CallIcon,
    },
    {
      id: "appointment",
      title: "appointment",
      href: t("appointment_link"),
      icon: CalendarIcon,
    },
    {
      id: "mail",
      title: "mail",
      href: "mailto:" + process.env.REACT_APP_HELP_EMAIL,
      icon: MailIcon,
    },
  ];

  const externalLinkNavigator = (url: string) => {
    if (Capacitor.getPlatform() !== "web") {
      inAppBrowser(url) ? navigate(url) : "";
    }
  };

  return (
    <>
      <div
        className={`${generalStyles.title} ${generalStyles.align_center} ${generalStyles.topMargin}`}
      >
        {t("helpcenter")}
      </div>
      <div className={styles.profile}>
        <div className={styles.helpSeparator}>{t("faq_title")}</div>
        <div className={styles.items}>
          {qaItems.map((item, i) => {
            return (
              <div key={item.id}>
                <div
                  className={`${styles.item} ${styles.itemWithBottomLine}`}
                  onClick={() => {
                    item.title === openQaItem
                      ? setOpenQaItem("")
                      : setOpenQaItem(item.title);
                  }}
                >
                  <div className={styles.itemText}>{t(item.title)}</div>
                  <div className={styles.itemArrow}>
                    <img
                      src={SmallArrowImage}
                      height={24}
                      width={24}
                      alt="arrow"
                    />
                  </div>
                </div>
                {item.title === openQaItem ? (
                  <div className={styles.itemTextDetail}>
                    {t(item.text)}
                    {item.disclaimer ? (
                      <div className={styles.itemTextDisclaimer}>
                        {t(item.disclaimer)}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        <div className={styles.helpSeparator}>{t("contact_title")}</div>
        <div className={styles.items}>
          {contactItems.map((item, i) => {
            return (
              <a
                href={item.href}
                key={item.id}
                target="_blank"
                rel="noreferrer"
                onClick={(event: any) => {
                  externalLinkNavigator(item.href);
                }}
              >
                <div className={styles.item}>
                  <div className={styles.itemIcon}>
                    <img src={item.icon} height={24} width={24} alt="" />
                  </div>
                  <div className={styles.itemText}>{t(item.title)}</div>
                  <div className={styles.itemArrow}>
                    <img
                      src={SmallArrowImage}
                      height={24}
                      width={24}
                      alt="arrow"
                    />
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
}
