import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./owner.module.scss";

function formatPhone(value: string) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = value.length;

  if (phoneNumberLength < 4) return phoneNumber;
  return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
    2,
    5
  )} ${phoneNumber.slice(5, 7)} ${phoneNumber.slice(7, 9)} ${phoneNumber.slice(
    9,
    11
  )} ${phoneNumber.slice(11, 13)}`;
}

export default function Owner({ data, no_mail, hide_right_box }: any) {
  const { t } = useTranslation("home");
  return (
    <>
      {data ? (
        <div
          className={
            styles.owner_wrapper +
            " " +
            `${hide_right_box ? styles.hide_right_box : styles.picture_bigger}`
          }
        >
          <div className={styles.left_wrapper}>
            {data.image ? (
              <img
                src={process.env.REACT_APP_IMG_URL + data.image}
                width={170}
                height={170}
              ></img>
            ) : (
              ""
            )}
          </div>
          <div className={styles.right_wrapper}>
            <div className={styles.role}>{t("owner_subtitle")}</div>
            {data.first_name && data.last_name ? (
              <div className={styles.name}>
                {data.first_name + " " + data.last_name}
              </div>
            ) : (
              ""
            )}
            <div className={styles.contact_wrapper}>
              {data.phone ? (
                <a className={styles.phone} href={"tel:" + data.phone}>
                  <span className={styles.phone_number}>
                    {formatPhone(data.phone)}
                  </span>
                  <span className={styles.phone_text}>
                    {t("bel") + " " + data.first_name}
                  </span>
                </a>
              ) : (
                ""
              )}

              {data.email ? (
                <a
                  className={styles.mail + " " + `${no_mail == true ? "" : ""}`}
                  href={"mailto:" + data.email}
                >
                  {t("mail") + " " + data.first_name}
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
