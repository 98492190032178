import React from "react";
import styles from "./number.module.scss";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";

export default function Number({
  bgColor,
  icon,
  numberBlockLink,
  number,
  unit,
  subunit,
  margin,
  marginLeft,
  marginRight,
  prefix,
  suffix,
  singleBlock,
}: {
  bgColor: "green" | "blue" | "purple";
  icon: any;
  numberBlockLink?: string;
  number: number;
  unit: string;
  subunit?: string;
  margin?: boolean;
  marginLeft?: boolean;
  marginRight?: boolean;
  prefix?: string;
  suffix?: string;
  singleBlock?: boolean;
}) {
  function getCorrectBg(color: string) {
    switch (color) {
      case "green":
        return styles.bgGreen;
      case "blue":
        return styles.bgBlue;
      case "purple":
        return styles.bgPurple;
      default:
        return styles.bgGreen;
    }
  }

  function MainData() {
    return (
      <div
        className={`${styles.block} ${getCorrectBg(bgColor)} ${
          margin ? styles.margin : ""
        } ${marginLeft ? styles.marginLeft : ""} ${
          marginRight ? styles.marginRight : ""
        } ${singleBlock ? styles.single_block : ""}`}
      >
        <div className={`${styles.icon}`}>
          <img src={icon} alt="number block image" width={48} height={48} />
        </div>
        <div className={styles.content_wrapper}>
          <span className={styles.number}>
            {
              <NumericFormat
                displayType="text"
                value={number}
                allowLeadingZeros
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={prefix}
                suffix={suffix}
                decimalScale={2}
              />
            }
          </span>
          <span className={styles.unit}>
            {unit}
            <div className={styles.subunit}>{subunit}</div>
          </span>
        </div>
      </div>
    );
  }

  return numberBlockLink ? (
    <Link to={numberBlockLink}>
      <MainData />
    </Link>
  ) : (
    <MainData />
  );
}
