import Api from "./api/api";
import { DateTime } from "luxon";

export type InvestmentDetailItem = {
  id: number;
  title: string;
  image: string;
  total_return: number;
  roi: number;
  amount_invested: number;
  investment_date: string;
  investment_date_expire: string;
  vme: boolean;
};

export type Investment = {
  id: number;
  image: string;
  amount: number;
  link: string;
  title: string;
  vme: boolean;
};

export type ProjectItem = {
  id: number;
  title: string;
  created: string;
  pi_label: string;
  field_header_images: any;
  path: {
    alias: string;
    langcode: string;
  };
  field_short_description: string;
};

export type Project = {
  data: ProjectItem[];
};

export type UserProjects = {
  total_return: number;
  total_amount: number;
  total_investments: number;
  investments: Investment[];
};

export type UserProjectsResponse = {
  data: UserProjects;
};

export type InvestmentResponse = {
  data: InvestmentDetailItem;
};

class ProjectService {
  getUserProjects(): Promise<UserProjectsResponse> {
    return Api.getApi().get("/jsonapi/user-projects");
  }

  getEntity(id: string): Promise<InvestmentResponse> {
    return Api.getApi().get(`/jsonapi/project-details/${id}`);
  }

  getAllProjects(language: string): Promise<Project> {
    return Api.getApi().get("/" + language + "/jsonapi/projects-app");
  }
}

export default new ProjectService();
