import React, { useState, useEffect } from "react";
import styles from "../../styles/pages/investments.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import Number from "../../components/number/number";
import CardImage from "../../components/card-with-image/card-with-image";
import BenefitsBlocks from "../../components/benefits/benefits";
import ProjectService, {
  Investment,
  Project,
  ProjectItem,
  UserProjects,
} from "../../services/project.service";
import SimpleSlider from "../../components/carousel-slider/carousel-slider";
import AcademyImage from "../../img/logos/194142/academy.svg";
import BuildingImage from "../../img/benefits/building.svg";
import StarImage from "../../img/benefits/star.svg";
import UppiesImage from "../../img/benefits/uppies.svg";
import EuroImage from "../../img/euro-blue.svg";
import InvestmentImage from "../../img/investment.svg";
import InvestmentImageBlue from "../../img/investment-blue.svg";
import EarnImage from "../../img/earn-arrow.svg";
import { useTranslation } from "react-i18next";
import Loading from "../../components/loading/Loading";
import SmallArrowImage from "../../img/small-arrow.svg";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

export default function Investments({
  cards,
  investments,
  projects,
  isLoading,
}: {
  cards: UserProjects | undefined;
  investments: Investment[];
  projects: ProjectItem[];
  isLoading: boolean;
}) {
  const { t } = useTranslation("investment");
  const [isShare, setIsShare] = useState<boolean>(false);
  const [isProperty, setIsProperty] = useState<boolean>(false);

  useEffect(() => {
    investments.forEach((investment) => {
      investment.vme ? setIsProperty(true) : setIsShare(true);
    });
  }, [investments]);

  return (
    <>
      {cards && investments && projects ? (
        <>
          <div className={`${generalStyles.title}`}>
            {t("your_investments")}
          </div>
          <div className={styles.numbers}>
            <Number
              icon={EarnImage}
              number={cards?.total_amount || 0}
              unit={t("invested")}
              bgColor="green"
              prefix="€ "
            />

            {isShare && cards && cards?.total_return > 0 ? (
              <Number
                icon={EuroImage}
                number={cards?.total_return || 0}
                unit={isProperty ? t("paid") + "*" : t("paid")}
                subunit={isProperty ? t("paid_subtext") : ""}
                bgColor="blue"
                prefix="€ "
              />
            ) : (
              ""
            )}

            <Number
              icon={
                isShare && cards && cards?.total_return > 0
                  ? InvestmentImage
                  : InvestmentImageBlue
              }
              number={cards?.total_investments || 0}
              unit={t("Investments")}
              bgColor={
                isShare && cards && cards?.total_return > 0 ? "purple" : "blue"
              }
            />
          </div>

          {investments ? (
            <div className={styles.projects}>
              {investments.map((investment, key) => (
                <CardImage
                  key={key}
                  image={`${process.env.REACT_APP_IMG_URL}${investment.image}`}
                  title={investment.title}
                  amount={investment.amount}
                  link={"/investments/" + investment.id}
                />
              ))}
            </div>
          ) : (
            <h3>{isLoading ? t("loading") : t("no_investments")}</h3>
          )}

          {projects ? (
            <>
              <div
                className={`${generalStyles.title} ${generalStyles.lessMargin}`}
              >
                {t("current offer")}
              </div>
              <div className={styles.slick_outer_wrapper}>
                <SimpleSlider data={projects} />
              </div>
            </>
          ) : null}

          <div className={styles.more}>
            <div className={generalStyles.title}>{t("benefits_title")}</div>
            <div className={styles.right}>
              <div className={styles.text}>
                <Link to="/benefits">{t("benefits_more")}</Link>
              </div>
              <div>
                <img src={SmallArrowImage} />
              </div>
            </div>
          </div>
          <div className={styles.benefits_blocks}>
            <BenefitsBlocks title={t("0% instapkosten")} img={BuildingImage} />
            <BenefitsBlocks
              title={t("VIP voorrang voor Upkot & Upliving")}
              img={StarImage}
            />
            <BenefitsBlocks
              title={t("3.000 Uppies bij volgende aankoop")}
              img={UppiesImage}
            />
            <BenefitsBlocks
              title={t("Upgrade Academy events & keynotes")}
              img={AcademyImage}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
