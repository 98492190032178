import Api from "./api/api";

export type User = {
  nr_uppies: number;
  email: string;
  first_name: string;
  last_name: string;
  investor_role: boolean;
  image: string;
  address: {
    street: string;
    city: string;
    postal_code: string;
  };
  phone: string;
  date_of_birth: string;
  partner: {
    first_name: string;
    last_name: string;
    date_of_birth_partner: string;
  };
  owner: {
    email: string;
    first_name: string;
    last_name: string;
    image: string;
    phone: string;
  };
};

export type UserResponse = {
  data: User;
};

export type UserDocument = {
  id: string;
  name: string;
  type: string;
  type_name: string;
};

export type NotificationSettings = {
  data: NotificationItem[];
};

export type LanguageSetting = "nl" | "en" | "fr" | "";

export type OnboardingSetting = boolean;

export type UserDocumentUri = {
  file_url: string;
};

export type UserDocumentsResponse = {
  data: UserDocument[];
};

export type NotificationItem = {
  id: number;
  name: string;
  name_original: string;
  active: boolean;
};

export type Time = { time: number };

export type DrupalTime = {
  data: Time;
};

class UserService {
  getUser(): Promise<UserResponse> {
    return Api.getApi().get("/jsonapi/users");
  }

  async getUserDocuments(): Promise<UserDocumentsResponse> {
    return Api.getApi().get("/jsonapi/user-documents");
  }

  async getDrupalTime(): Promise<DrupalTime> {
    return Api.getApi().get("/jsonapi/get-drupal-time");
  }

  async getDocumentUrl(document: UserDocument): Promise<UserDocumentUri> {
    const result = await Api.getApi().post("/jsonapi/request-file-url", {
      id: document.id,
      type: document.type,
    });

    // If drupal file add backend URL to file_url variable.
    if (document.type === "report") {
      result.data.file_url =
        process.env.REACT_APP_BACKEND_URL + result.data.file_url;
    }

    return result.data;
  }

  async getUserNotificationSettings(): Promise<NotificationItem[]> {
    const result = await Api.getApi().get("/jsonapi/user-settings");
    return result.data.notifications;
  }

  async updateUserNotificationSettings(
    notificationSettings: NotificationItem
  ): Promise<void> {
    await Api.getApi().patch("/jsonapi/user-settings", {
      notifications: { ...notificationSettings },
    });
  }

  async updateUserPushNotificationToken(token: string): Promise<void> {
    if (!token || token.trim().length === 0) {
      return;
    }
    await Api.getApi().patch("/jsonapi/user-settings", {
      firebase_token: token,
    });
  }

  async getUserLanguageSettings(): Promise<LanguageSetting> {
    const result = await Api.getApi().get("/jsonapi/user-settings");
    return result.data.language;
  }

  async updateUserLanguageSettings(language: LanguageSetting): Promise<void> {
    await Api.getApi().patch("/jsonapi/user-settings", {
      language: language,
    });
  }

  async getUserOnboardingSetting(): Promise<OnboardingSetting> {
    const result = await Api.getApi().get("/jsonapi/user-settings");
    return result.data.onboarding;
  }

  async updateUserOnboardingSettings(
    onboarding: OnboardingSetting
  ): Promise<void> {
    await Api.getApi().patch("/jsonapi/user-settings", {
      onboarding: onboarding,
    });
  }

  async requestUserUpdate(data: {
    partner1: { name: string; lastname: string; dob: string };
    partner2: { name: string; lastname: string; dob: string };
    email: string;
    phone: string;
    address: { postal_code: string; city: string; street: string };
  }): Promise<boolean> {
    const tokenResult = await Api.getApi().get("/session/token");
    const csrfToken = tokenResult.data;

    try {
      const result = await Api.getApi().post(
        "/webform_rest/submit",
        {
          webform_id: "my_upgrade_edit_profile",
          partner_1_first_name: data.partner1.name,
          partner_1_last_name: data.partner1.lastname,
          partner_1_birthdate: data.partner1.dob,
          partner_2_first_name: data.partner2.name,
          partner_2_last_name: data.partner2.lastname,
          partner_2_birthdate: data.partner2.dob,
          email: data.email,
          phone: data.phone,
          address: `${data.address.street} ${data.address.postal_code} ${data.address.city}`,
        },
        { headers: { "X-CSRF-Token": csrfToken } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      return false;
    }
  }

  async AddNewLead(data: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    description: string;
    type: string;
  }): Promise<boolean> {
    const tokenResult = await Api.getApi().get("/session/token");
    const csrfToken = tokenResult.data;

    try {
      const result = await Api.getApi().post(
        "/webform_rest/submit",
        {
          webform_id: "new_lead",
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone,
          description: data.description,
          type: data.type,
        },
        { headers: { "X-CSRF-Token": csrfToken } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      return false;
    }
  }

  async CashInUppies(data: {
    email: string;
    amount: number;
    type: string | null;
  }): Promise<boolean> {
    const tokenResult = await Api.getApi().get("/session/token");
    const csrfToken = tokenResult.data;

    try {
      const result = await Api.getApi().post(
        "/webform_rest/submit",
        {
          webform_id: "cash_in_form",
          email: data.email,
          amount: data.amount,
          type: data.type,
        },
        { headers: { "X-CSRF-Token": csrfToken } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      return false;
    }
  }

  async DonateSell(data: {
    project_id: any;
    text: string;
    type: string | null;
  }): Promise<boolean> {
    const tokenResult = await Api.getApi().get("/session/token");
    const csrfToken = tokenResult.data;

    try {
      const result = await Api.getApi().post(
        "/webform_rest/submit",
        {
          webform_id: "sell_donate_investment",
          text: data.text,
          type: data.type,
          project_id: data.project_id,
        },
        { headers: { "X-CSRF-Token": csrfToken } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      return false;
    }
  }

  async ValuationRequest(data: { project_id: any }): Promise<boolean> {
    const tokenResult = await Api.getApi().get("/session/token");
    const csrfToken = tokenResult.data;

    try {
      const result = await Api.getApi().post(
        "/webform_rest/submit",
        {
          webform_id: "valuation_request",
          project: data.project_id,
        },
        { headers: { "X-CSRF-Token": csrfToken } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      return false;
    }
  }

  async ReInvest(): Promise<boolean> {
    const tokenResult = await Api.getApi().get("/session/token");
    const csrfToken = tokenResult.data;

    try {
      const result = await Api.getApi().post(
        "/webform_rest/submit",
        {
          webform_id: "re_invest",
        },
        { headers: { "X-CSRF-Token": csrfToken } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      return false;
    }
  }
}

export default new UserService();
