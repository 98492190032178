import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Story from "../story/story";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { decode } from "html-entities";
import styles from "./carousel-slider.module.scss";

export default function SimpleSlider({ data }: any) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { t } = useTranslation("home");

  const checkBadge = (node: any) => {
    return node.field_type;
  };

  const checkDescription = (node: any) => {
    if (node.content_type == "Project") {
      return node.field_short_description;
    }

    return node.field_description ? node.field_short_description.value : "";
  };

  const checkImageField = (node: any) => {
    var file_field = [];
    // File field.
    if (node.content_type == "Project") {
      return process.env.REACT_APP_IMG_URL + "/" + node.field_header_image;
    } else {
      file_field = node.field_media_image;
      if (node.content_type == "press_item") {
        file_field = node.field_header_image;
      }

      if (file_field) {
        return process.env.REACT_APP_IMG_URL + "/" + file_field;
      }
    }

    return false;
  };

  const checkLink = (node: any) => {
    if (typeof node.path === "string" && node.path.substr(0, 4) == "http") {
      return node.path;
    } else {
      return (
        process.env.REACT_APP_IMG_URL +
        "/" +
        node.path.langcode +
        node.path.alias
      );
    }
  };

  const checkLinkText = (node: any) => {
    // File field.
    if (node.content_type == "Project") {
      return t("more_info");
    }

    return t("read_on");
  };

  const StyledSlider = styled(Slider)`
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      padding: 20px;
      height: inherit !important;
    }

    .slick-slide > div {
      height: 100%;
    }

    .slick-dots {
      bottom: -15px;
    }

    .slick-dots li button {
      height: 12px;
    }

    .slick-dots li {
      height: 12px;
      width: 15px;

      &.slick-active {
        width: 35px;
      }
    }

    .slick-dots li button:before {
      color: #c6d0d0;
      opacity: 0.6;
      font-size: 1.2rem;
      height: 12px;
      line-height: 14px;
    }

    .slick-dots li.slick-active button:before {
      width: 35px;
      background: #71d1d4;
      opacity: 1;
      content: "";
      border-radius: 25px;
    }
  `;

  return (
    <>
      {data ? (
        <StyledSlider {...settings}>
          {data.map((node: any, i: number) => {
            return (
              <div className={styles.slide_wrapper} key={i}>
                <Story
                  key={i}
                  title={decode(node.title)}
                  badge={checkBadge(node)}
                  body={checkDescription(node)}
                  image={`${checkImageField(node)}`}
                  link={checkLink(node)}
                  link_text={checkLinkText(node)}
                  slider={true}
                />
              </div>
            );
          })}
        </StyledSlider>
      ) : null}
    </>
  );
}
