import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationsService, {
  NotificationId,
  NotificationItem,
  Status,
  StatusId,
} from "../services/notifications.service";
import generalStyles from "../styles/generals.module.scss";
import CrossImage from "../img/cross.svg";
import NoNotifications from "../img/no_notifications.svg";

import MoreImage from "../img/points.svg";

import NewsIcon from "../img/notifications/news.svg";
import EventsIcon from "../img/notifications/events.svg";
import DocumentsIcon from "../img/notifications/documents.svg";
import ProjectIcon from "../img/notifications/projects.svg";
import UppiesIcon from "../img/notifications/uppies.svg";
import DeleteIcon from "../img/notifications/delete.svg";
import ReadIcon from "../img/notifications/read.svg";
import UnreadIcon from "../img/notifications/unread.svg";

import styles from "../styles/pages/notications.module.scss";
import moment from "moment";
import { stripHtml } from "string-strip-html";
import { DateTime } from "luxon";
import { User } from "../services/user.service";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "react-router-dom";
import inAppBrowser from "../services/mobile/browser";

export default function Notifications({
  notifications,
  setNotifications,
  isLoading,
  user,
}: {
  notifications: NotificationItem[];
  setNotifications: (items: NotificationItem[]) => void;
  isLoading: boolean;
  user: User | undefined;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<number | null>(null);
  const { t } = useTranslation("notifications");
  const actionsRef = useRef<any>();
  const [touchPosition, setTouchPosition] = useState(null);
  const [diff, setDiff] = useState<null | number>(null);
  const [swipingItem, setSwipingItem] = useState<null | number>(null);
  const [doneSwiping, setDoneSwiping] = useState(false);

  const redirects: { [key: string]: { external: boolean; url: string } } = {
    News: { external: true, url: "/events" },
    Uppies: { external: false, url: "/loyalty" },
    Projects: { external: true, url: "/" },
    Events: { external: true, url: "/events" },
  };

  const icons: Record<string, string> = {
    News: NewsIcon,
    Uppies: UppiesIcon,
    Projects: ProjectIcon,
    Events: EventsIcon,
    Documents: DocumentsIcon,
  };

  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionsRef]);

  const refetchNotificationsAndAction = async (
    id: NotificationId,
    status: StatusId,
    action: Status
  ) => {
    setLoading(true);
    await NotificationsService.updateNotificationStatus(id, status, action);
    const response = await NotificationsService.getNotifications();
    const sorted = response.data.sort((a, b) =>
      DateTime.fromISO(b.date).diff(DateTime.fromISO(a.date)).toMillis()
    );
    setNotifications(sorted);
    setLoading(false);
  };

  function handleTouchMove(e: any) {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    setDiff(diff);

    if (diff > 50) {
      setDiff(50);
      setTouchPosition(null);
      setDoneSwiping(true);
    }
  }

  return (
    <>
      {notifications ? (
        <div className={styles.notifications}>
          <div className={styles.heading}>
            <div
              className={`${generalStyles.title} ${generalStyles.noMargin} ${styles.title}`}
            >
              {t("notifications")}
            </div>
            <a
              href="#"
              title="Close notifications"
              className={styles.cross}
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={CrossImage} height={20} width={20} alt="cross" />
            </a>
          </div>
          <div className={styles.items}>
            {notifications.length < 1 ? (
              <>
                <div className={styles.subtitle}>{t("no_notifications")}</div>
                <img
                  className={styles.no_notifications}
                  src={NoNotifications}
                  height={300}
                  width={300}
                  alt="notifications"
                />
              </>
            ) : (
              notifications.map((notification, i) => {
                return (
                  <div
                    onClick={async (e) => {
                      e.preventDefault();
                      if (e.stopPropagation) e.stopPropagation();

                      if (notification.status === "unread") {
                        refetchNotificationsAndAction(
                          notification.id,
                          notification.status_entity,
                          "read"
                        );
                      }
                      if (notification.link) {
                        const url = notification.link_external
                          ? notification.link
                          : process.env.REACT_APP_BACKEND_URL +
                            notification.link;
                        if (Capacitor.getPlatform() !== "web") {
                          inAppBrowser(url);
                        } else {
                          window.open(url, "_blank");
                        }
                      } else {
                        navigate(redirects[notification.type].url);
                      }
                    }}
                    key={i}
                  >
                    <div
                      className={`${styles.relative} ${styles.item_wrapper} ${
                        styles[notification.status]
                      }`}
                    >
                      <div
                        className={`${styles.item} ${
                          styles[notification.status]
                        }`}
                        onTouchStart={(e: any) => {
                          const touchDown = e.touches[0].clientX;
                          setDoneSwiping(false);
                          setDiff(null);
                          setSwipingItem(notification.id);
                          setTouchPosition(touchDown);
                        }}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={() => {
                          if (doneSwiping || (diff && diff >= 50)) {
                            return;
                          }

                          setDiff(null);
                          setSwipingItem(null);
                          setTouchPosition(null);
                        }}
                        style={
                          notification.id === swipingItem && diff
                            ? {
                                transform: `translateX(-${diff}%)`,
                              }
                            : undefined
                        }
                      >
                        <div
                          className={`${styles.icon} ${
                            styles[notification.type]
                          }`}
                        >
                          <img
                            src={icons[notification.type]}
                            height={54}
                            width={54}
                            alt={"icon"}
                          />
                        </div>
                        <div className={styles.info}>
                          <div className={styles.title}>
                            {notification.label}
                          </div>
                          <div className={styles.description}>
                            {stripHtml(notification.description).result}
                          </div>
                        </div>
                        <div className={styles.date}>
                          {moment(notification.date).fromNow() == "a day ago"
                            ? "1 d."
                            : moment(notification.date)
                                .fromNow()
                                .split(" ")[0] +
                              " " +
                              moment(notification.date)
                                .fromNow()
                                .split(" ")[1]
                                .substring(0, 1) +
                              "."}
                        </div>
                        {Capacitor.getPlatform() === "web" ? (
                          <div
                            className={styles.dots}
                            onClick={(e) => {
                              e.stopPropagation();

                              setShowOptions(
                                showOptions === notification.id
                                  ? null
                                  : notification.id
                              );
                            }}
                          >
                            <img src={MoreImage} alt="options" />
                          </div>
                        ) : null}
                        {showOptions === notification.id ? (
                          <div className={styles.actions} ref={actionsRef}>
                            <div
                              className={styles.deleted}
                              onClick={async (e) => {
                                if (e.stopPropagation) e.stopPropagation();

                                if (loading) {
                                  return;
                                }

                                await refetchNotificationsAndAction(
                                  notification.id,
                                  notification.status_entity,
                                  "deleted"
                                );
                              }}
                            >
                              {t("Deze melding verwijderen")}
                            </div>
                            {notification.status === "unread" ? (
                              <div
                                className={styles.read}
                                onClick={async (e) => {
                                  if (e.stopPropagation) e.stopPropagation();

                                  if (loading) {
                                    return;
                                  }

                                  await refetchNotificationsAndAction(
                                    notification.id,
                                    notification.status_entity,
                                    "read"
                                  );
                                }}
                              >
                                {t("read")}
                              </div>
                            ) : (
                              <div
                                className={styles.unread}
                                onClick={async (e) => {
                                  if (e.stopPropagation) e.stopPropagation();

                                  if (loading) {
                                    return;
                                  }

                                  await refetchNotificationsAndAction(
                                    notification.id,
                                    notification.status_entity,
                                    "unread"
                                  );
                                }}
                              >
                                {t("unread")}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                      {doneSwiping && swipingItem === notification.id ? (
                        <div className={styles.swipedActions}>
                          <div
                            className={styles.delete}
                            onClick={async (e) => {
                              e.stopPropagation();

                              if (loading) {
                                return;
                              }

                              setDoneSwiping(false);
                              setSwipingItem(null);

                              await refetchNotificationsAndAction(
                                notification.id,
                                notification.status_entity,
                                "deleted"
                              );
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              height={28}
                              width={28}
                              alt={"delete"}
                            />
                            <div>{t("delete")}</div>
                          </div>
                          {notification.status === "unread" ? (
                            <div
                              className={styles.read}
                              onClick={async (e) => {
                                e.stopPropagation();

                                if (loading) {
                                  return;
                                }

                                setDoneSwiping(false);
                                setSwipingItem(null);

                                await refetchNotificationsAndAction(
                                  notification.id,
                                  notification.status_entity,
                                  "read"
                                );
                              }}
                            >
                              <img
                                src={ReadIcon}
                                height={28}
                                width={28}
                                alt={"delete"}
                              />
                              <div>{t("read")}</div>
                            </div>
                          ) : (
                            <div
                              className={styles.unread}
                              onClick={async (e) => {
                                e.stopPropagation();

                                if (loading) {
                                  return;
                                }

                                setDoneSwiping(false);
                                setSwipingItem(null);

                                await refetchNotificationsAndAction(
                                  notification.id,
                                  notification.status_entity,
                                  "unread"
                                );
                              }}
                            >
                              <img
                                src={UnreadIcon}
                                height={28}
                                width={28}
                                alt={"delete"}
                              />
                              <div>{t("unread")}</div>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      ) : (
        t("no_notifications")
      )}
    </>
  );
}
