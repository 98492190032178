import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import UserService, { User } from "./services/user.service";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const fallbackLng = ["nl"];
const availableLanguages = ["nl", "fr"];

// @ts-ignore
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLng,
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
