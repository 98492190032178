import styles from "./card-floating-img.module.scss";
import React from "react";

export default function CardFloatingImg(props: any) {
  return (
    <>
      <div className={styles.card_floating_img_wrapper}>
        <div className={styles.floting_box}>
          <div className={styles.floating_box_image}>
            <img
              src={props.icon}
              alt="number block image"
              width={30}
              height={30}
            />
          </div>
          <div className={styles.floating_text}>{props.text_floating}</div>
        </div>
        <div className={styles.content_box}>
          <div className={styles.content_box_text}>{props.text_content}</div>

          <a
            className={styles.link}
            href={props.link_href}
            target={props.target ? props.target : "_self"}
          >
            {props.link}
          </a>
        </div>
      </div>
    </>
  );
}
