import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import UserService, {
  NotificationItem,
  NotificationSettings,
} from "../../services/user.service";
import generalStyles from "../../styles/generals.module.scss";
import styles from "../../styles/pages/profile.module.scss";
import NewsIcon from "../../img/profile/notifications/news.svg";
import DocumentsIcon from "../../img/profile/notifications/documents.svg";
import EventsIcon from "../../img/profile/notifications/events.svg";
import InvestmentsIcon from "../../img/profile/notifications/investments.svg";
import UppiesIcon from "../../img/profile/notifications/uppies.svg";
import { useTranslation } from "react-i18next";

export default function ProfileNotifications() {
  const { t } = useTranslation("profile");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationItem[]>();
  const settingIcons: Record<string, string> = {
    News: NewsIcon,
    Documents: DocumentsIcon,
    Events: EventsIcon,
    Uppies: UppiesIcon,
    Projects: InvestmentsIcon,
  };

  useEffect(() => {
    UserService.getUserNotificationSettings().then((response) => {
      setNotifications(response);
    });
  }, []);

  return (
    <>
      <div className={`${generalStyles.title} ${generalStyles.align_center} `}>
        {t("notifications")}
      </div>
      {notifications ? (
        <div className={styles.profile}>
          <div className={`${styles.settings} ${styles.colouredBox} `}>
            {notifications.map((notification, i) => {
              return (
                <div
                  className={`${styles.item} ${styles.itemWithBottomLine}`}
                  key={i}
                >
                  <div className={styles.itemIcon}>
                    <img
                      width={24}
                      height={24}
                      src={settingIcons[notification.name_original]}
                      alt={"Notification settings icon"}
                    />
                  </div>
                  <div>
                    <div className={styles.itemText}>
                      {t(notification.name)}
                    </div>
                  </div>
                  <div className={styles.itemCheckbox}>
                    <Switch
                      checked={notification.active}
                      uncheckedIcon={false}
                      offColor="#EBEBEB"
                      onColor="#DDF49A"
                      width={44}
                      height={24}
                      handleDiameter={20}
                      checkedIcon={false}
                      disabled={isLoading}
                      onChange={async (state) => {
                        setLoading(true);
                        await UserService.updateUserNotificationSettings({
                          id: notification.id,
                          name: notification.name,
                          name_original: "",
                          active: state.valueOf(),
                        });
                        await UserService.getUserNotificationSettings().then(
                          (response) => {
                            setNotifications(response);
                          }
                        );
                        setLoading(false);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
