import Api from "./api/api";

export type QuestionnaireContent = {
  title: string;
  description: string;
  buttonText: string;
  questionnaireUrl: string;
};

export type QuestionnaireData = {
  nl?: QuestionnaireContent;
  en: QuestionnaireContent;
  fr?: QuestionnaireContent;
};

class QuestionnaireService {
  async getQuestionnaireData(): Promise<QuestionnaireData> {
    const response = await Api.getApi().get("/jsonapi/get-survey");
    return response.data;
  }
}

export default new QuestionnaireService();
