import React, { useEffect, useState } from "react";
import generalStyles from "../../styles/generals.module.scss";
import styles from "../../styles/pages/profile.module.scss";
import UserService, { User } from "../../services/user.service";
import { useTranslation } from "react-i18next";
import Loading from "../../components/loading/Loading";
import { useForm } from "react-hook-form";
import PopupUE from "../../components/popup/Popup";

export default function EditProfile({
  user,
  isLoading,
}: {
  user: User | undefined;
  isLoading: boolean;
}) {
  const [firstname, setFirstname] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [firstname2, setFirstname2] = useState<string>("");
  const [surname2, setSurname2] = useState<string>("");
  const [dob2, setDob2] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNr, setPhoneNr] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [city, setCity] = useState<string>("");

  const { register, handleSubmit, formState, reset, getValues } = useForm();

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>();

  const openPopup = () => {
    setPopup(true);
  };
  const closePopup = () => {
    setPopup(false);
  };

  const { t } = useTranslation("profile");
  useEffect(() => {
    if (!user) {
      return;
    }

    setFirstname(user.first_name);
    setSurname(user.last_name);
    setEmail(user.email);
    setCity(user.address.city);
    setStreet(user.address.street);
    setPostalCode(user.address.postal_code);
    setDob(user.date_of_birth);
    setPhoneNr(user.phone);
    if (user.partner) {
      setFirstname2(user.partner.first_name);
      setSurname2(user.partner.last_name);
      setDob2(user.partner.date_of_birth_partner);
    }
  }, [user]);

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return new Promise<void>(() => {
      setTimeout(() => {
        UserService.requestUserUpdate({
          partner1: { name: firstname, lastname: surname, dob: dob },
          partner2: { name: firstname2, lastname: surname2, dob: dob2 },
          email: email,
          phone: phoneNr,
          address: {
            street: street,
            postal_code: postalCode,
            city: city,
          },
        });

        reset();
        setSubmitting(false);
        openPopup();
      }, 3000);
    });
  };

  return (
    <>
      <div className={styles.profile}>
        <div className={`${generalStyles.title} ${generalStyles.align_center}`}>
          {t("edit_profile")}
        </div>
        {user ? (
          <div className={styles.container}>
            <div className={generalStyles.text}>{t("edit_profile_text")}</div>
            <div className={generalStyles.label}>
              {t("personal_information")}
            </div>
            {firstname2 && firstname2.length && surname2.length ? (
              <div className={styles.partnerLabel}>{t("partner")} 1</div>
            ) : null}
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register("firstname")}
                className={generalStyles.input}
                placeholder={t("name")}
                defaultValue={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
              />
              <input
                {...register("surname")}
                className={generalStyles.input}
                placeholder={t("surname")}
                defaultValue={surname}
                onChange={(e) => {
                  setSurname(e.target.value);
                }}
              />
              <input
                {...register("dob")}
                className={generalStyles.input}
                type={"date"}
                defaultValue={dob}
                onChange={(e) => {
                  setDob(e.target.value);
                }}
              />
              {firstname2 && firstname2.length && surname2.length ? (
                <>
                  <div className={styles.partnerLabel}>{t("partner")} 2</div>
                  <input
                    {...register("name")}
                    className={generalStyles.input}
                    placeholder={t("name")}
                    defaultValue={firstname2}
                    onChange={(e) => {
                      setFirstname2(e.target.value);
                    }}
                  />
                  <input
                    {...register("surname2")}
                    className={generalStyles.input}
                    placeholder={t("surname")}
                    defaultValue={surname2}
                    onChange={(e) => {
                      setSurname2(e.target.value);
                    }}
                  />
                  <input
                    {...register("dob2")}
                    className={generalStyles.input}
                    type={"date"}
                    defaultValue={dob2}
                    onChange={(e) => {
                      setDob2(e.target.value);
                    }}
                  />
                </>
              ) : null}
              <div className={generalStyles.label}>
                {t("contact_information")}
              </div>
              <input
                {...register("email")}
                className={generalStyles.input}
                placeholder={t("email")}
                defaultValue={email}
                type={"email"}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                {...register("phone")}
                className={generalStyles.input}
                placeholder={t("phone")}
                defaultValue={phoneNr}
                onChange={(e) => {
                  setPhoneNr(e.target.value);
                }}
              />
              <input
                {...register("street")}
                className={generalStyles.input}
                placeholder={t("street")}
                defaultValue={street}
                onChange={(e) => {
                  setStreet(e.target.value);
                }}
              />
              <input
                {...register("city")}
                className={generalStyles.input}
                placeholder={t("city")}
                defaultValue={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
              <input
                {...register("postal_code")}
                className={generalStyles.input}
                placeholder={t("postal_code")}
                defaultValue={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />

              <div>
                <PopupUE
                  title={t("edit_your_details")}
                  body={t("edit_your_details_body")}
                  icon=""
                  opened={popup}
                  close={closePopup}
                />
              </div>

              <button
                disabled={!formState.isDirty}
                className={`${generalStyles.button} ${generalStyles.green}`}
                type="submit"
              >
                {t("submit")}
              </button>
            </form>
          </div>
        ) : isLoading ? (
          <Loading />
        ) : null}
      </div>
    </>
  );
}
