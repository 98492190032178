import Api from "./api/api";

export type NotificationItem = {
  id: number;
  label: string;
  description: string;
  type: string;
  date: string;
  status: string;
  status_entity: number;
  link: string | undefined;
  link_external: boolean;
};

export type NotificationItems = {
  data: NotificationItem[];
};

export type Status = "deleted" | "read" | "unread";

export type StatusId = number;

export type NotificationId = number;

class NotificationsService {
  async getNotifications(): Promise<NotificationItems> {
    return Api.getApi().get("/jsonapi/user-notifications");
  }

  async updateNotificationStatus(
    notification_id: NotificationId,
    status_id: StatusId,
    status: Status
  ): Promise<void> {
    await Api.getApi().patch("/jsonapi/user-notifications", {
      status: status,
      status_id: status_id,
      notification_id: notification_id,
    });
  }
}

export default new NotificationsService();
