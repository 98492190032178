import React, { useEffect, useState } from "react";
import generalStyles from "../../styles/generals.module.scss";
import styles from "../../styles/forms/forms.module.scss";
import ProjectService, {
  InvestmentDetailItem,
} from "../../services/project.service";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import PopupUE from "../../components/popup/Popup";
import UserService from "../../services/user.service";
import { useForm } from "react-hook-form";
import Loading from "../../components/loading/Loading";

export default function SubmitSell() {
  const { id } = useParams();
  const [investment, setInvestment] = useState<
    InvestmentDetailItem | undefined
  >(undefined);
  const [message, setMessage] = useState("");
  const { t } = useTranslation("investment");

  const [popup, setPopup] = useState<boolean>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    ProjectService.getEntity(`${id}`).then((r) => {
      setInvestment(r.data);
    });
  }, [id]);

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return new Promise<void>(() => {
      setTimeout(() => {
        UserService.DonateSell({
          type: type,
          project_id: data.id,
          text: data.message,
        });
        reset();
        setMessage("");
        setSubmitting(false);
        openPopup();
      }, 3000);
    });
  };

  const openPopup = () => {
    setPopup(true);
  };
  const closePopup = () => {
    setPopup(false);
  };

  return (
    <>
      {investment ? (
        <div className={styles.submitSell}>
          <div
            className={`${generalStyles.title} ${generalStyles.align_center}`}
          >
            {type === "sell" ? t("sell_title_1") : t("donate_title_1")}
          </div>
          <div className={styles.container}>
            <div className={generalStyles.text}>
              {type === "sell" ? t("sell_subtitle") : t("donate_subtitle")}
            </div>
            <div className={styles.label}>
              {t("project_to_sell_label")}
              {type === "sell" ? t("sell_title") : t("donate_title")}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                className={generalStyles.input}
                disabled={true}
                value={investment?.title}
              />
              <input hidden {...register("id")} value={investment?.id}></input>
              <div className={styles.label}>{t("message")}</div>
              <textarea
                className={`${generalStyles.input} ${
                  errors.message ? styles.error : ""
                }`}
                value={message}
                {...register("message", { required: true })}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                rows={6}
                placeholder={t("message_placeholder")}
              />
              {errors.message && (
                <p className={styles.error_message}>{t("message_required")}</p>
              )}

              <div>
                <PopupUE
                  title={t("popup_sell_title")}
                  body={t("popup_sell_body")}
                  icon=""
                  opened={popup}
                  close={closePopup}
                />
              </div>

              <button
                disabled={isSubmitting}
                className={`${generalStyles.button} ${generalStyles.green}`}
              >
                {isSubmitting ? (
                  <span className={generalStyles.spinner}></span>
                ) : (
                  t("form_submit")
                )}
              </button>
            </form>
          </div>
        </div>
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}
