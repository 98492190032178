import React, { useState } from "react";
import generalStyles from "../../styles/generals.module.scss";
import UserService from "../../services/user.service";
import { useTranslation } from "react-i18next";
import PopupUE from "../../components/popup/Popup";
import styles from "../../styles/forms/forms.module.scss";
import { useForm } from "react-hook-form";
import PopupIcon from "../../img/popup_error.svg";

export default function NewLeadForm(props: { title: string; type: string }) {
  const [firstname, setFirstname] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNr, setPhoneNr] = useState<string>("");
  const [privacy, setPrivacy] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const formNames = ["firstname", "surname", "email", "phone", "privacy"];

  const type = props.type;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [popup, setPopup] = useState<boolean>();
  const [popupError, setPopupError] = useState<boolean>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation("new_lead");

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return new Promise<void>(() => {
      setTimeout(() => {
        UserService.AddNewLead({
          first_name: firstname,
          last_name: surname,
          email: email,
          phone: phoneNr,
          description: description,
          type: type,
        }).then((response) => {
          if (response) {
            reset();
            setFirstname("");
            setSurname("");
            setEmail("");
            setPhoneNr("");
            setPrivacy("");
            setDescription("");
            setSubmitting(false);
            openPopup();
          } else {
            setSubmitting(false);
            openErrorPopup();
          }
        });
      }, 3000);
    });
  };

  // Fallback for browser autofill.
  const onClick = () => {
    formNames?.forEach((item) => {
      document.getElementsByName(item)[0].focus();
    });
  };

  const openPopup = () => {
    setPopup(true);
  };
  const closePopup = () => {
    setPopup(false);
    window.location.reload();
  };

  const openErrorPopup = () => {
    setPopupError(true);
  };
  const closeErrorPopup = () => {
    setPopupError(false);
    window.location.reload();
  };

  return (
    <>
      <div className={styles.new_lead_form}>
        <div className={`${generalStyles.title} ${styles.title}`}>
          {props.title}
        </div>
        <div className={styles.intro}>{t(`intro_${props.type}_text`)}</div>
        <div className={generalStyles.label}>{t("personal_information")}</div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            className={`${generalStyles.input} ${
              errors.firstname ? styles.error : ""
            }`}
            placeholder={t("name")}
            value={firstname}
            {...register("firstname", { required: true })}
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
          />
          {errors.firstname && (
            <p className={styles.error_message}>First Name {t("required")}.</p>
          )}
          <input
            className={`${generalStyles.input} ${
              errors.surname ? styles.error : ""
            }`}
            placeholder={t("surname")}
            value={surname}
            {...register("surname", { required: true })}
            onChange={(e) => {
              setSurname(e.target.value);
            }}
          />
          {errors.surname && (
            <p className={styles.error_message}>Last Name {t("required")}.</p>
          )}
          <input
            className={`${generalStyles.input} ${
              errors.email ? styles.error : ""
            }`}
            placeholder={t("email")}
            value={email}
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            type={"email"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {errors.email && (
            <p className={styles.error_message}>Email {t("required")}.</p>
          )}
          <input
            className={`${generalStyles.input} ${
              errors.phone ? styles.error : ""
            }`}
            placeholder={t("phone")}
            value={phoneNr}
            {...register("phone", { required: true })}
            onChange={(e) => {
              setPhoneNr(e.target.value);
            }}
          />
          {errors.phone && (
            <p className={styles.error_message}>Phone {t("required")}.</p>
          )}
          <textarea
            name="description"
            className={`${generalStyles.input}`}
            placeholder={t("description")}
            value={description}
            rows={4}
            {...(register("description"), { required: true })}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <div className={styles.form_checkbox_privacy}>
            <input
              id="privacy"
              type={"checkbox"}
              value={privacy}
              {...register("privacy", { required: true })}
            />
            <label htmlFor="privacy">{t("privacy_checkbox_txt")}</label>
          </div>
          <div className={styles.form_checkbox_privacy}>
            <input
              id="consent"
              type={"checkbox"}
              value={privacy}
              {...register("consent", { required: true })}
            />
            <label htmlFor="consent">{t("consent_checkbox")}</label>
          </div>
          {(errors.privacy || errors.consent) && (
            <p className={styles.error_message}>{t("required")}.</p>
          )}
          <div>
            <PopupUE
              title={t("confirm_uppies_title")}
              body={t("confirm_uppies_body")}
              icon=""
              opened={popup}
              close={closePopup}
            />
          </div>

          <div>
            <PopupUE
              title={t("confirm_uppies_title_error")}
              body={t("confirm_uppies_body_error")}
              icon={PopupIcon}
              opened={popupError}
              close={closeErrorPopup}
            />
          </div>

          <button
            disabled={isSubmitting}
            className={`${generalStyles.button} ${generalStyles.green}`}
            onClick={onClick}
          >
            {isSubmitting ? (
              <span className={generalStyles.spinner} />
            ) : (
              t("submit")
            )}
          </button>
        </form>
      </div>
    </>
  );
}
